
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {Depot, Saldo, Payouts, Settings} from '@/store/depot/types';
import WithdrawDepot from '@/components/depot/WithdrawDepot.vue';

@Component({
  name: 'Withdraw-depot-view',
  components: {
    WithdrawDepot,
  },
})
export default class WithdrawDepotView extends Vue {
  @Getter('depot/depot') depot?: Depot;
  @Getter('depot/saldo') saldo?: Saldo;
  @Getter('depot/blocked') blocked?: boolean;
  @Getter('depot/payouts') payouts?: Payouts;
  @Getter('depot/settings') settings?: Settings;
}
