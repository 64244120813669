import moment from 'moment';
import LocalStorage from '@/utils/LocalStorage';

function getLocale() {
  return LocalStorage.get('i18nextLng').split('-')[0] || process.env.VUE_APP_EXPORO_LANGUAGE.split('-')[0];
}

/**
 *
 * @param date UnixTimestamp
 */
export function smallDate(date: number): string {

  return moment(new Date(date * 1000))
    .locale(getLocale())
    .format('L');
}
/**
 *
 * @param date UnixTimestamp
 */
export function date(date: number): string {

  return moment(new Date(date * 1000))
    .locale(getLocale())
    .format('LL');
}
/**
 *
 * @param date UnixTimestamp
 */
export function extendedDate(date: number): string {
  return moment(new Date(date * 1000))
    .locale(getLocale())
    .format('LLLL');
}

export function dateString(date: string): string {
  let a = date.split('-');

  return a[2] + '.' + a[1] + '.' + a[0];

}