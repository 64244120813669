
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
class ToggleButton extends Vue {
  @Prop({ default: false })
  private onToggle!: (boolean, string) => void;

  @Prop({ default: false })
  private value!: boolean;

  @Prop({ default: 'name' })
  private name!: string;

  private isChecked: boolean = false;

  constructor() {
    super();
  }

  mounted() {
    if (this.value) {
      this.toggle(false);
    }
  }

  toggle(fireCallback: boolean = true) {
    this.isChecked = !this.isChecked;

    if (fireCallback && this.onToggle) {
      this.onToggle(this.isChecked, this.name);
    }
  }
}

export default ToggleButton;
