
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { Depot, Saldo, Payouts, Settings } from '@/store/depot/types';
import { currency } from '@/filters/currency';
import { date } from '@/filters/date';
import Modal from '@/components/_common/Modal.vue';
import ToggleButton from '@/components/_common/ToggleButton.vue';
import { getIdToken } from '@/utils/Auth';
import { Brand } from '@/utils/brand-schemas';

const namespace: string = 'profile';

@Component({
  name: 'withdraw-depot',
  components: {
    Modal,
    ToggleButton,
  },
  filters: {
    currency,
    date,
  },
})
export default class WithdrawDepot extends Vue {
  @Prop({ required: true }) depot: Depot;
  @Prop({ required: true }) saldo: Saldo;
  @Prop({ required: false }) payouts: Payouts;
  @Prop({ required: true }) blocked: boolean;
  @Prop({ required: false }) settings: Settings;

  @Action('depot/createPayOut') createPayOut: Function;
  @Action('depot/updateSetting') updateSetting: Function;

  @Getter('appState/brandSchema') brandSchema?: Brand;

  private withdrawAmount: number = null;
  private error: boolean = this.blocked;
  private resultMessage: string = this.blockedMessage;
  private isLoading: boolean = false;
  private balance = this.saldo ? this.saldo.saldo : 0;
  private openPayouts = this.payouts && this.payouts.data ?
          this.payouts.data.reduce((sum, payout) => sum + parseFloat(payout.amount), 0) : 0;
  private availableBalance = this.balance - this.openPayouts;
  private automaticPayout = this.settings ? this.settings.automatic_payout: false;

  get hasResultMessage() {
    return this.resultMessage != '';
  }
  get supportEmail() {
    return this.brandSchema.supportEmail;
  }
  get supportPhone() {
    return this.brandSchema.supportPhone;
  }
  get blockedMessage() {
    return !this.blocked ? ''
      : this.$t('withdrawDepotModal.blocked', {
      supportlink: this.supportEmail,
      supportphone: this.supportPhone,
    });
  }
  get changeAccountText(){
    return this.$t('withdrawDepotModal.change_account_text', {
      supportlink: this.supportEmail,
      supportphone: this.supportPhone,
    });
  }
  censorText(ktok: string) {
    return ktok.slice(0,2) + '********' + ktok.slice(ktok.length - 4, ktok.length);
  }

  downloadBaseData(fileName: string) {
    return process.env.VUE_APP_BAADER_DEPOT_API_URL + '/cockpit/base_data/' + fileName + '?jwt=' + getIdToken();
  }

  get canSubmit() {
    return this.availableBalance && this.availableBalance > 0 &&
            !this.isLoading && !this.blocked &&
            this.withdrawAmount && this.withdrawAmount > 0 &&
            this.withdrawAmount <= this.availableBalance;
  }

  onToggle(isChecked, name) {
    const payload = {
      setting: name,
      value: isChecked,
    };

    this.isLoading = true;

    this.updateSetting(payload)
      .then((response) => {
      }).catch((err: any) => {
        this.error = true;
        if (err.response.status === 406) {
          this.resultMessage = this.$t('forms.error', { context: 'payout' });
        } else {
          this.resultMessage = this.$t('forms.error', { context: 'generic' });
        }
      })
      .finally(() => this.isLoading = false);
  }

  onSubmit() {
    const payload = {
      amount: this.withdrawAmount,
    };

    this.isLoading = true;

    this.createPayOut(payload)
      .then((response) => {
        this.$router.push('/');
      }).catch((err: any) => {
        this.error = true;
        if (err.response.status === 406) {
          this.resultMessage = this.$t('forms.error', { context: 'payout' });
        } else if (err.response.status === 409) {
          this.resultMessage = this.$t('forms.error', { context: 'openTrades' });
        } else {
          this.resultMessage = this.$t('forms.error', { context: 'generic' });
        }
      })
      .finally(() => this.isLoading = false);
  }

  onClose() {
    this.error = false;
    this.resultMessage = '';
  }
}
